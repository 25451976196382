import React from 'react';
import './Loading.css';

class Loading extends React.Component {
  render() {
    return (
      <div>
        <p id="bounceTxt" className="txtLoading">
          {this.props.text.split("").map((value, index) => (
            <em className={`bounce-me a${index}`} key={index}>{value}</em>
          ))}
        </p>
      </div>
    );
  }
}

Loading.defaultProps = {
  text: 'cargando'
};

export default Loading;
