import React from 'react';
import UneteEquipo from './components/UneteEquipo.js';
import Menu from './components/Menu.js';

// Otros importaciones de componentes...

const UniteAlEquipo = () => {
  return (
    <div className="vp-container">
    <div className="vp-main-content">
      <UneteEquipo />
    </div>
    <Menu />
  </div>
  );
};

export default UniteAlEquipo;
