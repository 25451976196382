import React from 'react';
import Account from './components/Account.js';
import Menu from './components/Menu.js';

// Otros importaciones de componentes...

const AccountPage = () => {
  return (
    <div className="vp-container">
    <div className="vp-main-content">
      <Account />
    </div>
    <Menu />
  </div>
  );
};

export default AccountPage;
