import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Modal from 'react-modal';
import HomePage from './HomePage.js';
import Register from './Register.js';
import RegisterWithGoogle from './RegisterWithGoogle.js';
import Login from './Login.js';
import Logout from './Logout.js';
import AdminPanel from './AdminPanel.js';
import VentasPage from './VentasPage.js';
import CompartiGana from './CompartiGana.js';
import UniteAlEquipo from './UniteAlEquipo.js';
import Ideas from './Ideas.js';
import SumarInmobiliaria from './SumarInmobiliaria.js';
import FaqPage from './FaqPage.js';
import AdminProperties from './components/AdminProperties.js';
import { useAuth } from './context/AuthContext.js';
import { getToken } from 'firebase/messaging'; 
import { messaging } from './firebaseConfig.js';
import AccountPage from './AccountPage.js';
import AlquileresPage from './AlquileresPage.js';
import MiOficina from './MiOficina.js';
import Consultas from './Consultas.js';
import Reservas from './Reservas.js';
import FilteredProperties from './FilteredProperties.js';
import PasswordReset from './PasswordReset.js';
import Success from './Success.js';
import Failure from './Failure.js';
import Pending from './Pending.js';

const App = () => {
  const { currentUser, userRole } = useAuth();

  useEffect(() => {
    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          const token = await getToken(messaging);
          console.log('FCM Token:', token);
          // Puedes enviar el token al servidor para gestionar notificaciones
        }
      } catch (error) {
        console.error('Error al solicitar permisos para notificaciones:', error);
      }
    };

    requestPermission();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/register" element={!currentUser ? <Register /> : <Navigate to="/" />} />
        <Route path="/register-google" element={!currentUser ? <RegisterWithGoogle /> : <Navigate to="/" />} />
        <Route path="/login" element={!currentUser ? <Login /> : <Navigate to="/" />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/logout" element={currentUser ? <Logout /> : <Navigate to="/" />} />
        <Route path="/ventas" element={<VentasPage/>} />
        <Route path="/propiedades/:filter" element={<FilteredProperties />} />
        <Route path="/alquileres" element={<AlquileresPage/>} />
        <Route path="/oficina" element={<MiOficina/>} />
        <Route path="/cuenta" element={<AccountPage />} />
        <Route path="/comparti-gana" element={<CompartiGana />} />
        <Route path="/se-parte" element={<UniteAlEquipo />} />
        <Route path="/sumar-inm" element={<SumarInmobiliaria />} />
        <Route path="/idea" element={<Ideas />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/reservas/:propertyId" element={<Reservas />} /> 
        <Route path="/consultas/:propertyId" element={<Consultas />} /> 
        <Route path="/admin" element={userRole === 'admin' ? <AdminPanel /> : <Navigate to="/" />} />
        <Route path="/admin/properties" element={userRole === 'admin' ? <AdminProperties /> : <Navigate to="/" />} />
        <Route path="/success" component={Success} />
        <Route path="/failure" component={Failure} />
        <Route path="/pending" component={Pending} />
      </Routes>
    </Router>
  );
};

export default App;
