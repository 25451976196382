// PasswordReset.js
import React, { useState } from 'react';
import { auth } from './firebaseConfig.js';
import { sendPasswordResetEmail } from 'firebase/auth';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Se ha enviado un correo para restablecer la contraseña.');
    } catch (error) {
      setMessage('Error al enviar el correo. Verifica el email.');
    }
  };

  return (
    <div className="login-container">
        <div className="login-box">
            <h2>Recuperar Contraseña</h2>
            <form onSubmit={handleResetPassword}>
            <div className="input-group">
                <input
                type="email" className="form-control"
                placeholder="Ingresa tu correo"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <button type="submit">Recuperar Contraseña</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    </div>
  );
};

export default PasswordReset;
