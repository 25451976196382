import React, { useEffect } from 'react';
import { auth } from './firebaseConfig.js';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const signOutUser = async () => {
      await auth.signOut();
      navigate('/');
    };
    signOutUser();
  }, [navigate]);

  return (
    <div>
      <p>Cerrando sesión...</p>
    </div>
  );
};

export default Logout;
