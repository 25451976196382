import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext.js';
import { db } from '../firebaseConfig.js';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import PropertyModal from './PropertyModal.js';
import './RentalPropertyCard.css';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import axios from "axios";


// Función para calcular el precio total
const calculateTotalPrice = (checkInDate, checkOutDate, basePricePerNight) => {
  const checkIn = new Date(checkInDate);
  const checkOut = new Date(checkOutDate);
  const differenceInTime = checkOut.getTime() - checkIn.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  return basePricePerNight * differenceInDays;
};

// Función para calcular el 15% del precio total
const calculatePaymentAmount = (totalPrice) => {
  return totalPrice * 0.15;
};

const RentalPropertyCard = ({ property, archivos = [], checkInDate: initialCheckInDate, checkOutDate: initialCheckOutDate }) => {
  const { currentUser } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [reserveModalOpen, setReserveModalOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [checkInDate, setCheckInDate] = useState(initialCheckInDate);
  const [checkOutDate, setCheckOutDate] = useState(initialCheckOutDate);
  
  const [preferenceId, setPreferenceId] = useState(null);

  initMercadoPago('APP_USR-35061d8e-2fbf-4b04-837f-12042e0d5d6a', {
    locale: "es-AR",
  });

  const backendUrl = process.env.NODE_ENV === 'production'
  ? 'https://us-central1-reservos-20bac.cloudfunctions.net/api'  // URL correcta
  : 'http://localhost:5000';

 
  const createPreference = async () =>{
    try {
      const response = await axios.post(`${backendUrl}/mercadopago/create_preference`, {
        title: "alquiler temporario - mar del plata",
        quantity: 1,
        price: 100,
      });
      const { id } = response.data;
      return id;   
    } catch (error) {
      console.log(error);
    }
  };

  const handleBuy = async () =>{
    const id = await createPreference();
    if (id){
      setPreferenceId(id);
    }
  };  

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % archivos.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + archivos.length) % archivos.length);
  };

  const handleShare = () => {
    setShareOpen(!shareOpen);
  };

  const handleCopyLink = () => {
    const url = `${window.location.origin}/property/${property.id}?ref=${currentUser?.uid}`;
    navigator.clipboard.writeText(url).then(() => {
      alert('Enlace copiado al portapapeles');
    });
  };

  const handleReserve = () => {
    if (!currentUser) {
      alert('Debes estar logueado para reservar.');
      return;
    }
    setReserveModalOpen(true);
  };
  

  if (!checkInDate || !checkOutDate) {
    return <p>Cargando fechas...</p>;
  }
  
  return (
    <div className="cont-bus-prop">
      <div className="carousel">
        <div className="carousel-inner">
          {archivos.map((img, index) => (
            <div
              key={index}
              className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
              style={{ transform: `rotateY(${index === currentIndex ? '0deg' : '90deg'})` }} >
              <img src={img.fileURL} className="d-block w-100 imgprop" alt={`${property.tipo} ${property.amb} en ${property.barrio} - ${property.ciudad}`} />
            </div>
          ))}
        </div>
        <i className="fa-solid fa-chevron-left carousel-control-prev" onClick={prevSlide}></i>
        <i className="fa-solid fa-chevron-right carousel-control-next" onClick={nextSlide}></i>
      </div>
      <div className="titulo-propiedad">
        <h2>{`${property.tipo} ${property.amb} ambientes en ${property.barrio} - USD ${property.publicacion}`}</h2>
      </div>
      <div className="botones-prop">
        <ul>
          <li className="li-prop" onClick={handleShare}><i className="fa-solid fa-share-from-square"></i></li>
          <li className="li-prop" onClick={() => setModalOpen(true)}><i className="fa-solid fa-circle-info"></i></li>
          <li className="li-prop" onClick={handleReserve}><i className="fa-solid fa-calendar-check"></i></li>
        </ul>
      </div>
      {shareOpen && (
        <div className="conteinerIconShare">  
          <h3>Compartí esta propiedad y ganá recompensas en dólares</h3>
          <p>Compartí las propiedades del sitio, si una persona se registra desde tu enlace, recibirás recompensas cuando realice su primer compra, venta o alquiler.</p>
          <ul className="wrapper-icons sci">
            <li className="icon comentarios">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/property/${property.id}?referrer=${currentUser?.uid || ''}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  <i className="fa-brands fa-facebook"></i>
                </span>
              </a>
            </li>
            <li className="icon comentarios">
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.origin}/property/${property.id}?referrer=${currentUser?.uid || ''}&title=Encontrá%20las%20mejores%20oportunidades%20inmobiliarias`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  <i className="fa-brands fa-linkedin"></i>
                </span>
              </a>
            </li>
            <li className="icon comentarios">
              <a
                href={`https://twitter.com/intent/tweet?text=Encontrá%20las%20mejores%20oportunidades%20inmobiliarias%20${window.location.origin}/property/${property.id}?referrer=${currentUser?.uid || ''}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  <i className="fa-brands fa-x-twitter"></i>
                </span>
              </a>
            </li>
            <li className="icon comentarios">
              <a
                href={`https://api.whatsapp.com/send?text=${window.location.origin}/property/${property.id}?referrer=${currentUser?.uid || ''}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  <i className="fa-brands fa-whatsapp"></i>
                </span>
              </a>
            </li>
            <li className="icon comentarios" onClick={handleCopyLink}>
              <span>
                <i className="fa-solid fa-link"></i>
              </span>
            </li>
          </ul> 
        </div>     
      )}
      {modalOpen && <PropertyModal property={property} onClose={() => setModalOpen(false)} />}
      {reserveModalOpen && (
        <div className="reserve-modal">
          <button className="btn-close" onClick={() => setReserveModalOpen(false)}>X</button>
          <h2>Reserva tu propiedad</h2>
          <div>
            <p>Fecha de inicio: {checkInDate}</p>
            <p>Fecha de fin: {checkOutDate}</p>
          </div>
          <div>
            <p>Dirección: {property.direccion}</p>
            <p>Precio por noche: ${property.publicacion}</p>
            <p>Total de noches: {calculateTotalPrice(checkInDate, checkOutDate, property.publicacion)}</p>
            <p>Reserva con: ${calculatePaymentAmount(calculateTotalPrice(checkInDate, checkOutDate, property.publicacion))}</p>
          </div>
          <button onClick={handleBuy}>Reservar ahora</button>

          {preferenceId && <Wallet initialization={{ preferenceId }} />}
          
        </div>      
      )}
    </div>
  );
};

export default RentalPropertyCard;
