import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate
import './Segmentos.css';
import reciclados from '../assets/oportunidad.jpg';
import casas from '../assets/Casa_de_Lujo.jpg';
import oportunidad from '../assets/oportunidad-inmo.png';
import vistas from '../assets/propiedades.jpg';

const Segmentos = () => {
  const navigate = useNavigate(); // Hook para la navegación

  // Función para manejar la navegación según el filtro
  const handleNavigation = (filter) => {
    navigate(`/propiedades/${filter}`); // Redirigimos a una ruta con el filtro
  };

  return (
    <div className="conteiner-segmentos">
      <ul>
        <li tabIndex="0" onClick={() => handleNavigation('reciclados-a-estrenar')}>
          <div className="img-seg">
            <img src={reciclados} alt="Departamento reciclado a estrenar" />
          </div>
          Reciclados / A Estrenar
        </li>
        <li tabIndex="0" onClick={() => handleNavigation('solo-casas')}>
          <div className="img-seg">
            <img src={casas} alt="Casas en venta" />
          </div>
          Solo Casas
        </li>
        <li tabIndex="0" onClick={() => handleNavigation('oportunidades')}>
          <div className="img-seg">
            <img src={oportunidad} alt="Oportunidades inmobiliarias" />
          </div>
          Reales Oportunidades
        </li>
        <li tabIndex="0" onClick={() => handleNavigation('vistas')}>
          <div className="img-seg">
            <img src={vistas} alt="Vistas panoramicas" />
          </div>
          Vistas
        </li>
      </ul>
    </div>
  );
};

export default Segmentos;
