import React, { useState } from "react";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import "./IdeaForm.css";

const IdeaForm = () => {
  const [descIdea, setDescIdea] = useState("");
  const [necesita, setNecesita] = useState("");
  const [oferta, setOferta] = useState("");
  const [pplan, setPplan] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!descIdea || !necesita || !oferta || !pplan) {
      setError("Por favor completa todos los campos.");
      return;
    }

    const db = getFirestore();
    try {
      await addDoc(collection(db, "ideas"), {
        descIdea,
        necesita,
        oferta,
        pplan,
        createdAt: new Date(),
      });
      setSuccess("¡Tu idea ha sido enviada con éxito!");
      setError("");
      setDescIdea("");
      setNecesita("");
      setOferta("");
      setPplan("");
    } catch (error) {
      setError("Lo siento, no se pudo enviar tu idea. Por favor intenta más tarde.");
      setSuccess("");
    }
  };

  return (
    <div className="parteIdea">
      <div className="cont-into">
        <h2 data-text="Innova...">Innova...</h2>
      </div>
      <div className="container-idea">
        <h4>Por favor coméntanos de qué trata tu idea y cómo podemos ayudarte</h4>
        <form className="formbuss" id="formIdea" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="descidea" className="form-label">
              Danos una breve descripción de tu idea
            </label>
            <textarea
              className="form-control"
              name="descidea"
              id="descidea"
              value={descIdea}
              onChange={(e) => setDescIdea(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-3">
            <select
              className="form-select"
              name="necesita"
              value={necesita}
              onChange={(e) => setNecesita(e.target.value)}
            >
              <option value="">¿Qué necesitas de nosotros?</option>
              <option value="alianza">Alianza Comercial</option>
              <option value="asesoria">Asesoría</option>
              <option value="tecnologia">Tecnología</option>
              <option value="marketing">Marketing</option>
              <option value="inversion">Inversión</option>
              <option value="otros">Otros</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="oferta" className="form-label">
              ¿Qué porcentaje ofreces de tu negocio?
            </label>
            <input
              type="text"
              className="form-control"
              name="oferta"
              id="oferta"
              value={oferta}
              onChange={(e) => setOferta(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="pplan" className="form-label">
              Tienes un plan de negocios y/o presentación de tu proyecto. Pega un enlace a Google Drive donde tengas alojados tus archivos.
            </label>
            <input
              type="text"
              className="form-control"
              name="pplan"
              id="pplan"
              placeholder="https://drive.google.com/"
              value={pplan}
              onChange={(e) => setPplan(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn_cantidad" style={{ width: "180px" }}>
            Enviar Idea
          </button>
        </form>
        {success && (
          <div id="exitoIdea" className="alert alert_moli_exito" role="alert">
            <div>{success}</div>
          </div>
        )}
        {error && (
          <div id="errorIdea" className="alert alert_moli_error" role="alert">
            <div>{error}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IdeaForm;
