import React from 'react';
import IdeaForm from './components/IdeaForm.js';
import Menu from './components/Menu.js';

// Otros importaciones de componentes...

const Ideas = () => {
  return (
    <div className="vp-container">
    <div className="vp-main-content">
      <IdeaForm />
    </div>
    <Menu />
  </div>
  );
};

export default Ideas;