import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext.js';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig.js'; 
import { useNavigate } from 'react-router-dom'; // Para redirigir a nuevas páginas
import Loading from './Loading.js';
import './Office.css';

const Office = () => {
  const { currentUser } = useAuth();
  const [properties, setProperties] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [propertiesPerPage] = useState(5); // Cantidad de propiedades por página
  const navigate = useNavigate();

  // Obtener las propiedades del usuario
  useEffect(() => {
    const fetchProperties = async () => {
      if (currentUser) {
        const propertiesRef = collection(db, 'properties');
        const q = query(propertiesRef, where('idUsuario', '==', currentUser.uid));
        const querySnapshot = await getDocs(q);
        setProperties(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      }
    };

    fetchProperties();
  }, [currentUser]);

  // Búsqueda de propiedades por dirección
  const filteredProperties = properties.filter((property) =>
    property.direccion.toLowerCase().includes(search.toLowerCase())
  );

  // Paginación
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = filteredProperties.slice(indexOfFirstProperty, indexOfLastProperty);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Redirigir a la página de consultas o reservas
  const viewDetails = (property, type) => {
    if (type === 'reservas') {
      navigate(`/reservas/${property.id}`);
    } else if (type === 'consultas') {
      navigate(`/consultas/${property.id}`);
    }
  };

  if (!currentUser) {
    return <div id="carga" className="containerLoading">
    <Loading />
  </div>;
  }

  return (
    <div className="office-container">
      <div className="propietario-section">
        <h2>Tus Propiedades</h2>

        <input
          type="text"
          placeholder="Buscar por dirección..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="search-input-mis-prop"
        />

        {/* Lista de propiedades con paginación */}
        {currentProperties.map(property => (
          <div key={property.id} className="property-card">
            <h3>{property.direccion}</h3>
            {property.operacion === 'alquiler temporario' ? (
              <button onClick={() => viewDetails(property, 'reservas')}>Ver Reservas</button>
            ) : (
              <button onClick={() => viewDetails(property, 'consultas')}>Ver Consultas</button>
            )}
          </div>
        ))}

        {/* Paginador */}
        <div className="pagination">
          {[...Array(Math.ceil(filteredProperties.length / propertiesPerPage)).keys()].map(number => (
            <button key={number + 1} onClick={() => paginate(number + 1)} className="btn moli-o">
              {number + 1}
            </button>
          ))}
        </div>

        <div className="add-property">
          <h3>¿Deseas cargar otra propiedad?</h3>
          <a href="https://wa.me/xxxxxx" className="btn-wsp">Comunícate por WhatsApp</a>
        </div>
      </div>
    </div>
  );
};

export default Office;
