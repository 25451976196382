import React from 'react';
import './PropertyModal.css';

const PropertyModal = ({ property, onClose }) => {
  if (!property) return null;

  // Filtrar los campos booleanos que son verdaderos
  const trueBooleans = Object.entries(property)
    .filter(([key, value]) => typeof value === 'boolean' && value)
    .map(([key]) => key);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>×</button>
        <h2>{`${property.tipo} ${property.amb} ambientes en ${property.barrio} - USD ${property.publicacion}`}</h2>
        <p><strong>Dirección:</strong> {property.direccion}</p>
        <p><strong>Barrio:</strong> {property.barrio}</p>
        <p><strong>Ciudad:</strong> {property.ciudad}</p>
        <p><strong>Descripción:</strong> {property.descrip}</p>
        
        <div className="boolean-list">
          {trueBooleans.length > 0 ? (
            trueBooleans.map((boolKey) => (
              <div key={boolKey} className="boolean-item">
                {boolKey.charAt(0).toUpperCase() + boolKey.slice(1).replace(/([A-Z])/g, ' $1')}
              </div>
            ))
          ) : (
            <p>No hay características destacadas.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyModal;
