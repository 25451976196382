import React, { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebaseConfig.js';
import { Link } from 'react-router-dom';
import RegisterWithGoogle from './RegisterWithGoogle.js';
import './Register.css'; 

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [referrer, setReferrer] = useState(null); // Estado para almacenar el UID del referido

  // Hook para obtener el referrer desde localStorage
  useEffect(() => {
    const storedReferrer = localStorage.getItem('referrer');
    if (storedReferrer) {
      setReferrer(storedReferrer);
    }
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const firestore = getFirestore();

    try {
      // Verifica si el email ya está registrado en Firestore
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('email', '==', email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Si ya existe un usuario con ese email, muestra una alerta y termina la función
        alert('El correo ya está registrado. Por favor, utiliza otro.');
        return;
      }

      // Si el correo no está registrado, procede a crear el usuario
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Construye el objeto de datos para guardar en Firestore
      const userData = {
        email: user.email,
        role: 'user', // Cambia a 'admin' si es necesario
      };

      // Si existe un UID de referido, lo agrega al campo 'referido'
      if (referrer) {
        userData.referido = referrer;
      }

      // Guarda el usuario en Firestore con el rol y el referido (si aplica)
      await setDoc(doc(firestore, 'users', user.uid), userData);

      alert('Registro exitoso');
    } catch (error) {
      console.error('Error registrando el usuario:', error);
      alert('Error registrando el usuario');
    }
  };

  return (
    <div className="register-container">
      <div className="register-box">
        <h2>Registro</h2>
        <form onSubmit={handleRegister}>
          <div className="input-group">
            <label htmlFor="email">Correo electrónico</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Ingresa tu correo"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Contraseña</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Crea una contraseña"
              required
            />
          </div>
          <button type="submit" className="register-button">Registrarse</button>
        </form>

        <p className="login-link">
          ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión aquí</Link>
        </p>

        <div className="google-register">
          <RegisterWithGoogle />
        </div>
      </div>
    </div>
  );
};

export default Register;
