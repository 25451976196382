import React, { useState } from 'react';
import Modal from 'react-modal'; // Asegúrate de tener instalado react-modal
import PropertyCard from './PropertyCard.js'; // El componente que se mostrará en el modal
import { useAuth } from '../context/AuthContext.js'; // Correcta importación de useAuth
import './CardGeolocation.css';

const CardGeolocalization = ({ property, archivos = [] }) => {
  const [consulta, setConsulta] = useState(''); // Estado para la consulta
  const { currentUser } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false); // Estado para controlar el modal

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % archivos.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + archivos.length) % archivos.length);
  };

  // Función para abrir y cerrar el modal
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="cont-bus-geo">
      <div className="carousel-geo">
        <div className="carousel-inner-geo">
          {archivos.map((img, index) => (
            <div
              key={index}
              className={`carousel-item-geo ${index === currentIndex ? 'active' : ''}`}
              style={{ transform: `rotateY(${index === currentIndex ? '0deg' : '90deg'})` }}
            >
              <img src={img.fileURL} alt={`${property.tipo} ${property.amb} en ${property.barrio} - ${property.ciudad}`} />
            </div>
          ))}
        </div>
        <i className="fa-solid fa-chevron-left carousel-control-prev-geo" onClick={prevSlide}></i>
        <i className="fa-solid fa-chevron-right carousel-control-next-geo" onClick={nextSlide}></i>
      </div>
      <div className="titulo-propiedad-geo">
        <h2>{`${property.tipo} ${property.amb} ambientes - USD ${property.publicacion}`}</h2>
      </div>
      <i className="fa-solid fa-eye" onClick={openModal} ></i>

      {modalOpen && (
        <Modal isOpen={modalOpen} onRequestClose={closeModal} contentLabel="Detalles de la Propiedad" className="modal-content" overlayClassName="modal-overlay">
          <PropertyCard property={property} archivos={property.archivos || []}/> 
          <button onClick={closeModal} className="close-modal-btn">
            Cerrar
          </button>
        </Modal>
      )}
    </div>
  );
};

export default CardGeolocalization;
