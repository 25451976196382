import React, { useEffect } from 'react';
import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

// Importar los módulos desde rutas específicas
import { Autoplay, EffectCoverflow } from 'swiper/modules';

import publica from '../assets/publica-prop.png';
import comparte from '../assets/compartiygana.png';
import nosotros from '../assets/nosotros.png';
import './CarouselPromos.css';

const CarouselPromos = () => {
  useEffect(() => {
    const swiper = new Swiper('.threeD-slider', {
      modules: [Autoplay, EffectCoverflow], // Usar los módulos importados correctamente
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      speed: 1200,
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
    });
  }, []);

  return (
    <div className="carousel-promos">
      <div className="swiper-container threeD-slider">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <img src={publica} alt="Publica vende tu propiedad, venta de propiedades, vende tu inmueble" />
            <div className="texto-carousel-promo">
              <h5>Publica tu propiedad</h5>
              <p>Te ayudamos a hacerlo.</p>
              <button type="button" className="btn btn-compra">Info</button>
            </div>
          </div>
          <div className="swiper-slide">
            <img src={comparte} alt="Comparti y ganá. Compartí las propiedades del sitio para ganar recompensas." />
            <div className="texto-carousel-promo">
              <h5>Compartí y Ganá</h5>
              <p>Compartí las propiedades del portal y ganá premios todos los meses.</p>
            </div>
          </div>
          <div className="swiper-slide">
            <img src={nosotros} alt="Somos jóvenes que buscan innovar y dar claridad al mercado inmobiliario" />
            <div className="texto-carousel-promo">
              <h5>¿Qué hacemos?</h5>
              <p>Ayudamos a que tengas una buena experiencia comprando, vendiendo o alquilando una propiedad, simplemente tenemos un portal inmobiliario.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselPromos;
