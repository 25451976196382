import React from "react";
import "./Faq.css";

const FAQ = () => {
  return (
    <div className="faq-container">
      <h1 className="faq-title">Preguntas Frecuentes</h1>
      <div className="faq-content">
        <div className="faq-item">
          <h3 className="faq-question">¿Cómo funciona el alquiler temporario en Reservos?</h3>
          <p className="faq-answer">
            En Reservos, los precios de los alquileres temporarios se ajustan automáticamente en función de la demanda. Esto significa que, durante periodos de alta demanda, los precios pueden aumentar, mientras que durante periodos de baja demanda, los precios pueden disminuir para atraer más reservas. Este modelo dinámico asegura que obtengas el mejor valor posible por tu propiedad.
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">¿Hay algún costo por publicar mi propiedad en venta?</h3>
          <p className="faq-answer">
            No, publicar tu propiedad en venta en Reservos es completamente gratuito. Puedes subir toda la información y las imágenes de tu propiedad sin ningún cargo. Además, todas las inmobiliarias registradas en la plataforma tienen la posibilidad de encontrar un comprador para tu propiedad, lo que incrementa las posibilidades de venta.
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">¿Qué debo hacer si necesito ajustar el precio de mi propiedad?</h3>
          <p className="faq-answer">
            Ajustar el precio de tu propiedad es sencillo. Simplemente accede a tu cuenta, ve a la sección de administración de propiedades y selecciona la propiedad que deseas ajustar. Desde allí, puedes actualizar el precio y cualquier otro detalle que consideres necesario. Es recomendable revisar y ajustar el precio de tu propiedad regularmente para mantenerla competitiva en el mercado.
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">¿Cómo puedo aumentar la visibilidad de mi propiedad en la plataforma?</h3>
          <p className="faq-answer">
            Para aumentar la visibilidad de tu propiedad, asegúrate de que el anuncio esté completo y bien presentado. Incluye fotos de alta calidad, una descripción detallada y asegúrate de que la información sea precisa. Además, puedes utilizar opciones de promoción dentro de la plataforma para destacar tu propiedad en las búsquedas.
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">¿Qué tipo de soporte ofrece Reservos si tengo dudas o problemas?</h3>
          <p className="faq-answer">
            Reservos ofrece soporte completo para todos los usuarios. Si tienes alguna duda o encuentras un problema, puedes contactarnos a través del chat en vivo disponible en la plataforma, enviarnos un correo electrónico, o llamar a nuestro número de soporte. Nuestro equipo está listo para ayudarte en todo lo que necesites.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
