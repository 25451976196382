import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import './AdminProperties.css'; // Importar el archivo CSS
import { ref, deleteObject, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebaseConfig.js'; // Asegúrate de que esta importación sea correcta

const AdminProperties = () => {
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const propertiesPerPage = 5;

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [editForm, setEditForm] = useState({
    direccion: '',
    publicacion: '',
    archivos: []
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bajaReason, setBajaReason] = useState('');
  const [salePrice, setSalePrice] = useState('');

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const propertiesCollection = collection(db, 'properties');
        const propertiesSnapshot = await getDocs(propertiesCollection);
        const propertiesList = propertiesSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(property => !property.venta || property.venta === null || property.venta === ''); // Filtra propiedades no vendidas ni retiradas
        setProperties(propertiesList);
        setFilteredProperties(propertiesList);
      } catch (error) {
        console.error('Error fetching properties:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  useEffect(() => {
    setFilteredProperties(
      properties.filter(property =>
        (property.direccion?.toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
        (property.descrip?.toLowerCase().includes(searchTerm.toLowerCase()) || '')
      )
    );
  }, [searchTerm, properties]);

  const handleDelete = (property) => {
    setSelectedProperty(property);
    setShowDeleteModal(true);
  };

  const handleEdit = (property) => {
    setSelectedProperty(property);
    setEditForm({
      direccion: property.direccion || '',
      publicacion: property.publicacion || '',
      archivos: property.archivos || []
    });
    setShowEditModal(true);
  };

  const handleBajaSubmit = async () => {
    try {
      const updatedPrice = bajaReason === 'vendido' ? salePrice : '0';
      await updateDoc(doc(db, 'properties', selectedProperty.id), {
        venta: updatedPrice,
        motivo: bajaReason
      });
      setProperties(properties.filter(prop => prop.id !== selectedProperty.id));
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error updating property:', error);
    }
  };

  const handleEditSubmit = async () => {
    try {
      // Elimina imágenes antiguas
      if (editForm.archivos.length > 0) {
        for (const file of selectedProperty.archivos) {
          const imageRef = ref(storage, file.fileName);
          await deleteObject(imageRef);
        }
      }

      // Sube nuevas imágenes
      const newFileURLs = [];
      for (const file of editForm.archivos) {
        const imageRef = ref(storage, file.fileName);
        await uploadBytes(imageRef, file);
        const fileURL = await getDownloadURL(imageRef);
        newFileURLs.push({ fileName: file.fileName, fileURL });
      }

      await updateDoc(doc(db, 'properties', selectedProperty.id), {
        direccion: editForm.direccion,
        publicacion: editForm.publicacion,
        archivos: newFileURLs
      });

      setProperties(properties.map(prop =>
        prop.id === selectedProperty.id ? { ...prop, ...editForm, archivos: newFileURLs } : prop
      ));
      setShowEditModal(false);
    } catch (error) {
      console.error('Error updating property:', error);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setEditForm({
      ...editForm,
      archivos: files.map(file => ({
        fileName: file.name,
        fileURL: URL.createObjectURL(file) // Se genera una URL temporal para la vista previa
      }))
    });
  };

  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = filteredProperties.slice(indexOfFirstProperty, indexOfLastProperty);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <p>Cargando propiedades...</p>;
  }

  return (
    <div>
      <h3>Lista de Propiedades</h3>

      <input 
        type="text" 
        className="inputMolis" 
        placeholder="Buscar propiedades..." 
        value={searchTerm} 
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <table>
        <thead>
          <tr>
            <th>Imagen</th>
            <th>Dirección</th>
            <th>Publicacion</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {currentProperties.map(property => (
            <tr key={property.id}>
              <td>
                {property.archivos && property.archivos.length > 0 ? (
                  <img src={property.archivos[0].fileURL} alt="Imagen de la propiedad" className="thumbnail" />
                ) : (
                  <span>No image available</span>
                )}
              </td>
              <td>{property.direccion}</td>
              <td>{property.publicacion}</td>
              <td>
                <button onClick={() => handleEdit(property)}>Editar</button>
                <button onClick={() => handleDelete(property)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        propertiesPerPage={propertiesPerPage}
        totalProperties={filteredProperties.length}
        paginate={paginate}
        currentPage={currentPage}
      />

      {showEditModal && (
        <div className="modal">
          <div className="modal-content">
            <h4>Editar Propiedad</h4>
            <form onSubmit={(e) => { e.preventDefault(); handleEditSubmit(); }}>
              <label>Dirección:</label>
              <input 
                type="text" 
                value={editForm.direccion} 
                onChange={(e) => setEditForm({ ...editForm, direccion: e.target.value })}
              />

              <label>Publicación:</label>
              <input 
                type="text" 
                value={editForm.publicacion} 
                onChange={(e) => setEditForm({ ...editForm, publicacion: e.target.value })}
              />

              <label>Imágenes:</label>
              <input 
                type="file" 
                multiple 
                onChange={handleFileChange}
              />
              <div>
                {editForm.archivos.map((file, index) => (
                  <img key={index} src={file.fileURL} alt={`Preview ${index}`} className="thumbnail" />
                ))}
              </div>

              <button type="submit">Guardar cambios</button>
              <button onClick={() => setShowEditModal(false)}>Cancelar</button>
            </form>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="modal">
          <div className="modal-content">
            <h4>Baja de Propiedad</h4>
            <p>Motivo de baja:</p>
            <select value={bajaReason} onChange={(e) => setBajaReason(e.target.value)}>
              <option value="">Seleccione una opción</option>
              <option value="retirado">Se retira del mercado</option>
              <option value="vendido">Se vendió</option>
            </select>
            {bajaReason === 'vendido' && (
              <div>
                <p>Precio de venta:</p>
                <input
                  type="number"
                  value={salePrice}
                  onChange={(e) => setSalePrice(e.target.value)}
                  placeholder="Ingrese el precio de venta"
                />
              </div>
            )}
            <button onClick={handleBajaSubmit}>Confirmar</button>
            <button onClick={() => setShowDeleteModal(false)}>Cancelar</button>
          </div>
        </div>
      )}
    </div>
  );
};

const Pagination = ({ propertiesPerPage, totalProperties, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalProperties / propertiesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map(number => (
          <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
            <a onClick={() => paginate(number)} href="#" className="page-link">
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default AdminProperties;
