import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import CardGeolocalization from './CardGeolocalization.js';
import PropertyCard from './PropertyCard.js'; // Importa el componente para el modal
import './Geolocalizacion.css';


const haversineDistance = (coords1, coords2) => {
  const toRad = (value) => (value * Math.PI) / 180;
  const R = 6371; // Radio de la Tierra en km
  const dLat = toRad(coords2.lat - coords1.lat);
  const dLon = toRad(coords2.lng - coords1.lng);
  const lat1 = toRad(coords1.lat);
  const lat2 = toRad(coords2.lat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distancia en km

  return d;
};

const Geolocalizacion = () => {
  const [isTracking, setIsTracking] = useState(
    localStorage.getItem('isTracking') === 'true' || false
  );
  const [location, setLocation] = useState(null);
  const [propertiesInRange, setPropertiesInRange] = useState([]);
  const [minDistance, setMinDistance] = useState(null);
 const [showProperties, setShowProperties] = useState(false); // Controla si se muestran las propiedades

  useEffect(() => {
    if (isTracking) {
      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          localStorage.setItem('watchId', watchId);
        },
        (error) => console.error(error),
        { enableHighAccuracy: true, maximumAge: 60000, timeout: 150000 }
      );

      localStorage.setItem('isTracking', 'true');
    } else {
      const savedWatchId = localStorage.getItem('watchId');
      if (savedWatchId) {
        navigator.geolocation.clearWatch(parseInt(savedWatchId));
        localStorage.removeItem('watchId');
      }
    }

    return () => {
      const savedWatchId = localStorage.getItem('watchId');
      if (savedWatchId) {
        navigator.geolocation.clearWatch(parseInt(savedWatchId));
        localStorage.removeItem('watchId');
      }
    };
  }, [isTracking]);

  const handleTrackingToggle = () => {
    setIsTracking((prevIsTracking) => !prevIsTracking);
  };

  const handleShowProperties = async () => {
    if (!location) return;

    // Si las propiedades ya están visibles, al hacer clic las oculta
    if (showProperties) {
      setShowProperties(false);
      return;
    }

    const propertiesSnapshot = await getDocs(collection(db, 'properties'));
    const propertiesList = propertiesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const propertiesWithDistance = propertiesList
      .filter((property) => {
        const propertyLocation = property.ubicacion;
        return (
          propertyLocation &&
          typeof propertyLocation.latitude === 'number' &&
          typeof propertyLocation.longitude === 'number' &&
          property.operacion === 'venta' // Filtro para propiedades en venta
        );
      })
      .map((property) => {
        const distance = haversineDistance(
          { lat: location.lat, lng: location.lng },
          { lat: property.ubicacion.latitude, lng: property.ubicacion.longitude }
        );
        return { ...property, distance };
      })
      .filter((property) => property.distance <= 0.5) // Filtro para propiedades dentro de 500 metros
      .sort((a, b) => a.distance - b.distance) // Ordenar por distancia
      .slice(0, 8); // Limitar a las 8 más cercanas

    if (propertiesWithDistance.length > 0) {
      setMinDistance(propertiesWithDistance[0].distance);
    }

    setPropertiesInRange(propertiesWithDistance);
    setShowProperties(true);
  };


  return (
    <div className="geolocalizacion">
      <button className="btnSwichtUbi" onClick={handleTrackingToggle}>
        {isTracking ? 'Desactivar Ubicación' : 'Activar Ubicación'}
      </button>
      <span className="iHistorial" onClick={handleShowProperties}>
        <i className="fa-solid fa-clock-rotate-left"></i>
      </span>
      <div>
        {minDistance !== null && (
          <ul>
            {propertiesInRange.map((property) => (
              <li key={property.id}>
                <CardGeolocalization property={property} archivos={property.archivos || []} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Geolocalizacion;
