import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebaseConfig.js';
import { useAuth } from './context/AuthContext.js';
import Loading from './components/Loading.js';

const Consultas = () => {
  const { propertyId } = useParams();
  const { currentUser } = useAuth();
  const [property, setProperty] = useState(null);
  const [consultas, setConsultas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const consultasPerPage = 5; // Número de consultas por página
  const navigate = useNavigate();

  // Obtener la propiedad y verificar si el usuario es el propietario
  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const propertyRef = doc(db, 'properties', propertyId);
        const propertySnap = await getDoc(propertyRef);

        if (propertySnap.exists()) {
          const propertyData = propertySnap.data();

          // Verificar si el usuario autenticado es el propietario de la propiedad
          if (propertyData.idUsuario !== currentUser.uid) {
            alert('No tienes permiso para ver las consultas de esta propiedad.');
            navigate('/office'); // Redirige si no es el propietario
          } else {
            setProperty(propertyData); // Si es el propietario, mostrar las consultas
          }
        } else {
          console.log('La propiedad no existe.');
          navigate('/office'); // Redirige si la propiedad no existe
        }
      } catch (error) {
        console.error('Error al obtener la propiedad:', error);
        navigate('/office'); // Redirige en caso de error
      }
    };

    if (currentUser) {
      fetchProperty();
    }
  }, [currentUser, propertyId, navigate]);

  // Obtener las consultas de la colección 'consultas' para esta propiedad
  useEffect(() => {
    const fetchConsultas = async () => {
      if (property) {
        try {
          const consultasRef = collection(db, 'consultas');
          const q = query(consultasRef, where('propertyId', '==', propertyId));
          const querySnapshot = await getDocs(q);
          const consultasData = querySnapshot.docs.map(doc => doc.data());
          setConsultas(consultasData);
        } catch (error) {
          console.error('Error al obtener las consultas:', error);
        }
      }
    };

    fetchConsultas();
  }, [property, propertyId]);

  // Obtener las consultas actuales para la página actual
  const indexOfLastConsulta = currentPage * consultasPerPage;
  const indexOfFirstConsulta = indexOfLastConsulta - consultasPerPage;
  const currentConsultas = consultas.slice(indexOfFirstConsulta, indexOfLastConsulta);

  // Cambiar la página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (!property) {
    return  <div id="carga" className="containerLoading">
    <Loading />
  </div>;
  }

  return (
    <div className="containerMisReservas">
      <h2>Consultas para {property.direccion}</h2>
      {currentConsultas.length > 0 ? (
        <ul>
          {currentConsultas.map((consulta, index) => (
            <li key={index}>
              <p>Mensaje: {consulta.consulta}</p>
              <small>Fecha: {consulta.createdAt}</small>
              <hr />
            </li>
          ))}
        </ul>
      ) : (
        <p>No hay consultas.</p>
      )}

      {/* Paginador */}
      <div className="pagination">
        {Array.from({ length: Math.ceil(consultas.length / consultasPerPage) }, (_, i) => (
          <button key={i + 1} onClick={() => paginate(i + 1)} className={currentPage === i + 1 ? 'active' : 'btn moli-o'}>
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Consultas;
