import React from 'react';
import { Link } from 'react-router-dom';
import './Pending.css'; // Importa el CSS para estilos personalizados

const Pending = () => {
  return (
    <div className="pending-container">
      <h1>Pago Pendiente</h1>
      <p>Tu pago está siendo procesado. Por favor, espera mientras verificamos el estado.</p>
      <Link to="/" className="button">Volver al Inicio</Link>
    </div>
  );
};

export default Pending;
