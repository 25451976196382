import React, { useState } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import './UneteEquipo.css';

const UneteEquipo = () => {
  const [selectedPuesto, setSelectedPuesto] = useState('');
  const [formValues, setFormValues] = useState({
    pre0: '',
    pre1: '',
    pre2: '',
    pre3: '',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const db = getFirestore();
  
  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handlePuestoChange = (e) => {
    setSelectedPuesto(e.target.value);
  };

  const enviarSolicitud = async () => {
    setLoading(true);
    setMessage('');

    try {

      await addDoc(collection(db, 'solicitudes'), {
        puesto: selectedPuesto,
        pre0: formValues.pre0,
        pre1: formValues.pre1,
        pre2: formValues.pre2,
        pre3: formValues.pre3,
        createdAt: new Date(),
      });

      setMessage('Solicitud enviada con éxito.');
      setFormValues({
        pre0: '',
        pre1: '',
        pre2: '',
        pre3: '',
      });
      setSelectedPuesto('');
    } catch (error) {
      setMessage('Error al enviar la solicitud. Por favor, inténtalo de nuevo.');
    }

    setLoading(false);
  };

  return (
      <div className="contaner-unete">
        <div className="cont-into">
          <h2 data-text="Puestos...">Puestos...</h2>
          <div className="container-text-e oculto"></div>
        </div>
        <div className="contaner-idea">
          <form id="formJobs" encType="multipart/form-data">
            <h4>¡Se parte de nuestro equipo!</h4>
            <select className="form-select" name="puesto" onChange={handlePuestoChange} value={selectedPuesto} id="puestos">
              <option value="">Seleccioná una categoría</option>
              <option value="Marketing">Marketing</option>
              <option value="RRHH">RRHH</option>
              <option value="Admin">Administrativos</option>
              <option value="Finanzas">Finanzas</option>
              <option value="Tecnologia">Tecnología</option>
            </select>

            {selectedPuesto && (
              <div className="puesto-e">
                <div className="mb-3">
                  <label htmlFor="pre0" className="form-label">Detallanos tu experiencia y si tenes alguna especialidad</label>
                  <textarea className="form-control" maxLength="240" name="pre0" value={formValues.pre0} onChange={handleChange} placeholder="solo tienes 240 caracteres"></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="pre1" className="form-label">
                    {selectedPuesto === 'Marketing' && 'Estamos lanzando como nuevo producto, un hotel para milenials. Empezará a difundirse en mes y medio. ¿Se te ocurre algún concepto? ¿Qué canales deberíamos usar? ¿Cómo lo lanzarías?'}
                    {selectedPuesto === 'RRHH' && '¿Qué acciones llevarías a cabo para motivar a un departamento?'}
                    {selectedPuesto === 'Admin' && '¿Qué tipo de tareas administrativas tienes experiencia realizando?'}
                    {selectedPuesto === 'Finanzas' && '¿Cómo manejarías una situación en la que los gastos de la empresa están superando los ingresos?'}
                    {selectedPuesto === 'Tecnologia' && 'Cuéntanos sobre tu experiencia con las tecnologías que manejas.'}
                  </label>
                  <textarea className="form-control" maxLength="500" name="pre1" value={formValues.pre1} onChange={handleChange} placeholder="solo tienes 500 caracteres"></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="pre2" className="form-label">
                    {selectedPuesto === 'Marketing' && 'Una empresa ha aumentado un 30% sus ventas el mes pasado, pero solo un 10% podría volver a comprar, ¿Cómo harías para retener un mayor número de clientes?'}
                    {selectedPuesto === 'RRHH' && '¿Por qué se rige a la hora de seleccionar personal?'}
                    {selectedPuesto === 'Admin' && '¿Cómo manejas la priorización de tareas administrativas cuando todas parecen urgentes?'}
                    {selectedPuesto === 'Finanzas' && 'Describe una estrategia que usarías para optimizar el flujo de caja de una empresa.'}
                    {selectedPuesto === 'Tecnologia' && 'Cuéntanos de un proyecto tecnológico complejo que hayas manejado y cómo lo resolviste.'}
                  </label>
                  <textarea className="form-control" maxLength="500" name="pre2" value={formValues.pre2} onChange={handleChange} placeholder="solo tienes 500 caracteres"></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="pre3" className="form-label">¿Por qué quieres ser parte de Molis y por qué deberíamos contratarte?</label>
                  <textarea className="form-control" maxLength="500" name="pre3" value={formValues.pre3} onChange={handleChange} placeholder="solo tienes 500 caracteres"></textarea>
                </div>
              </div>
            )}

            <button type="button" onClick={enviarSolicitud} className="btn btn-cantidad" disabled={loading} style={{ width: '160px' }}>
              {loading ? 'Enviando...' : 'Enviar Solicitud'}
            </button>
          </form>

          {message && (
            <div className={`alert ${message.includes('éxito') ? 'alert-moli-exito' : 'alert-moli-error'}`} role="alert">
              <div>{message}</div>
            </div>
          )}
        </div>
      </div>
  );
};

export default UneteEquipo;
