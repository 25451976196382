import React, { useState, useEffect } from 'react';
import { storage, db } from '../firebaseConfig.js';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, getDocs, addDoc, serverTimestamp, updateDoc, doc, GeoPoint } from 'firebase/firestore';
import './AddProperty.css';

const AddProperty = ({ currentUserId }) => {
  const [formData, setFormData] = useState({
    inm: '',
    propietario: '',
    direccion: '',
    barrio: '',
    ciudad: '',
    operacion: '',
    tipo: '',
    publicacion: '',
    amb: '',
    personas: '',
    orientacion: '',
    mcub: '',
    mdes: '',
    expensas: '',
    estado: '',
    dep: false,
    cochera: false,
    baulera: false,
    vista: '',
    financia: false,
    permuta: false,
    credito: false,
    llaves: '',
    motivo: '',
    descrip: '',
    idUsuario: '',
    createdAt: null,
  });
  const [files, setFiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);


  useEffect(() => {
    if (currentUserId) {
      setFormData((prevData) => ({
        ...prevData,
        idUsuario: currentUserId,
      }));
    }
  }, [currentUserId]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, 'users'); // Accede a la colección de usuarios
        const usersSnapshot = await getDocs(usersCollection); // Obtén todos los documentos de la colección
        const usersData = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setUsers(usersData);
      } catch (error) {
        console.error("Error al obtener usuarios: ", error);
      }
    };
  
    fetchUsers();
  }, []);
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      const searchTerm = value;
  
 
      if (searchTerm !== undefined && searchTerm !== null) {
        const lowerCaseTerm = searchTerm.toLowerCase();
  
        const filteredUsers = users.filter(user => {
          const fullName = user.fullName || ''; // Asegúrate de que fullName no sea undefined
          return fullName.toLowerCase().includes(lowerCaseTerm);
        });
  
        setFilteredUsers(filteredUsers);
      }
  
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  

  const handleUserSelect = (user) => {
    setFormData({
      ...formData,
      propietario: `${user.fullName} (${user.phoneNumber})`,
      idUsuario: user.id, // Guardar el ID del usuario seleccionado
    });
    setFilteredUsers([]); // Limpiar las sugerencias una vez que se selecciona un usuario
  };

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const getCoordinates = async (direccion) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(direccion)}&key=${apiKey}`;
  
    try {
      const response = await fetch(url);
      const data = await response.json();
  
      if (data.status === "OK" && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        return {
          latitude: location.lat,
          longitude: location.lng
        };
      } else {
        console.error("Error en la respuesta de la API de Google Maps:", data);
        throw new Error("No se pudieron obtener las coordenadas.");
      }
    } catch (error) {
      console.error("Error al intentar obtener las coordenadas:", error);
      throw new Error("No se pudieron obtener las coordenadas.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const { direccion, ciudad, barrio } = formData;
      const fullAddress = `${direccion}, ${barrio}, ${ciudad}`;
      const coordinates = await getCoordinates(fullAddress);
  
      const ubicacionGeoPoint = new GeoPoint(coordinates.latitude, coordinates.longitude);
  
      const docRef = await addDoc(collection(db, 'properties'), {
        ...formData,
        createdAt: serverTimestamp(),
        ubicacion: ubicacionGeoPoint,
      });
  
      const uploadPromises = Array.from(files).map(async (file) => {
        const fileRef = ref(storage, `properties/${docRef.id}/${file.name}`);
        await uploadBytesResumable(fileRef, file);
        const downloadURL = await getDownloadURL(fileRef);
        return { fileName: file.name, fileURL: downloadURL };
      });
  
      const fileURLs = await Promise.all(uploadPromises);
  
      await updateDoc(doc(db, 'properties', docRef.id), {
        archivos: fileURLs,
      });
  
      alert('Propiedad agregada exitosamente!');
    } catch (error) {
      console.error('Error al agregar la propiedad: ', error);
      alert('Ocurrió un error al cargar la propiedad. Inténtalo de nuevo.');
    }
  };

  return (
    <div className="add-property">
      <h3>Cargar Nueva Propiedad</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Empresa - Martillero</label>
          <input
            type="text" className="form-control"
            name="inm"
            value={formData.inm}
            onChange={handleChange}
            placeholder="Nombre empresa Reg.(1111)"
          />
        </div>
        <div className="form-group">
          <label>Propietario / Asesor</label>
          <input
            type="text" className="form-control"
            name="propietario"
            value={formData.propietario}
            onChange={handleChange}
            placeholder="Nombre y teléfono"
            autoComplete="off"
          />
          {filteredUsers.length > 0 && (
            <ul className="user-suggestions">
              {filteredUsers.map((user) => (
                <li key={user.id} onClick={() => handleUserSelect(user)}>
                  {user.fullName} ({user.phoneNumber})
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="form-group">
          <label>Ciudad</label>
          <input type="text" className="form-control" name="ciudad" value={formData.ciudad} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Barrio</label>
          <input type="text" className="form-control" name="barrio" value={formData.barrio} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Dirección</label>
          <input type="text" className="form-control" name="direccion" value={formData.direccion} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Operación</label>
          <select name="operacion" className="form-control" value={formData.operacion} onChange={handleChange}>
            <option value="">Selecciona una operación</option>
            <option value="venta">Venta</option>
            <option value="alquiler temporario">Alquiler Temporario</option>
          </select>
        </div>
        <div className="form-group">
          <label>Tipo de propiedad</label>
          <select name="tipo" className="form-control" value={formData.tipo} onChange={handleChange}>
            <option value="">Selecciona un tipo</option>
            <option value="Campo">Campo</option>
            <option value="Casa">Casa</option>
            <option value="Cochera">Cochera</option>
            <option value="Departamento">Departamento</option>
            <option value="Duplex">Duplex</option>
            <option value="En pozo">En pozo</option>
            <option value="Galpon">Galpón</option>
            <option value="Hotel">Hotel</option>
            <option value="Local">Local</option>
            <option value="Lote">Lote</option>
            <option value="Oficina">Oficina</option>
            <option value="Ph">PH</option>
          </select>
        </div>
        <div className="form-group">
          <label>Cantidad de ambientes</label>
          <input type="number" className="form-control" name="amb" value={formData.amb} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Precio de publicación</label>
          <input type="number" className="form-control" name="publicacion" value={formData.publicacion} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Cantidad de personas</label>
          <input className="form-control" type="number" name="personas" value={formData.personas} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Ubicación</label>
          <select className="form-control" name="orientacion" value={formData.orientacion} onChange={handleChange}>
            <option value="">Selecciona una ubicación</option>
            <option value="Frente">Frente</option>
            <option value="Lateral">Lateral</option>
            <option value="Contrafrente">Contrafrente</option>
            <option value="Interior">Interior</option>
          </select>
        </div>
        <div className="form-group">
          <label>Estado de la propiedad</label>
          <select className="form-control" name="estado" value={formData.estado} onChange={handleChange}>
            <option value="">Selecciona una opción</option>
            <option value="A reciclar">A reciclar</option>
            <option value="Bueno">Bueno</option>
            <option value="Muy bueno">Muy bueno</option>
            <option value="Excelente">Excelente</option>
            <option value="A estrenar">A estrenar</option>
            <option value="Reciclado">Reciclado</option>
            <option value="Al pozo">Al pozo</option>
          </select>
        </div>
        <div className="form-group">
          <label>Metros cubiertos</label>
          <input className="form-control" type="number" name="mcub" value={formData.mcub} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Metros descubiertos</label>
          <input type="number" name="mdes" value={formData.mdes} onChange={handleChange} />
        </div>
        <div className="conteinerInputCheck">
          <div className="mb-3">
            <label>
              <input type="checkbox" name="dep" checked={formData.dep} onChange={handleChange} />
              Dependencia
            </label>
          </div>
          <div className="mb-3">
            <label>
              <input type="checkbox" name="cochera" checked={formData.cochera} onChange={handleChange} />
              Cochera
            </label>
          </div>
          <div className="mb-3">
            <label>
              <input type="checkbox" name="baulera" checked={formData.baulera} onChange={handleChange} />
              Baulera
            </label>
          </div>
          <div className="mb-3">
          <label>
            <input type="checkbox" name="financia" checked={formData.financia} onChange={handleChange} />
            Financia
          </label>
          </div>
          <div className="mb-3">
            <label>
              <input type="checkbox" name="permuta" checked={formData.permuta} onChange={handleChange} />
              Acepta permuta
            </label>
          </div>
          <div className="mb-3">
            <label>
              <input type="checkbox" name="credito" checked={formData.credito} onChange={handleChange} />
              Apto crédito
            </label>
          </div>
        </div>

        <div className="form-group">
          <label>Expensas</label>
          <input type="number" name="expensas" value={formData.expensas} onChange={handleChange} />
        </div>
        
        <div className="form-group">
          <label>Vista</label>
          <select className="form-control" name="vista" value={formData.vista} onChange={handleChange}>
            <option value="">Selecciona una vista</option>
            <option value="Al mar">Al mar</option>
            <option value="Al río">Al río</option>
            <option value="A la calle">A la calle</option>
            <option value="Al parque">Al parque</option>
            <option value="Panorámica">Panorámica</option>
          </select>
        </div>
      
        <div className="form-group">
          <label>Entrega de llaves</label>
          <input type="text" className="form-control" name="llaves" value={formData.llaves} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Motivo de la venta</label>
          <textarea
            name="motivo" className="form-control"
            value={formData.motivo}
            onChange={handleChange}
            placeholder="Describe el motivo"
          />
        </div>
        <div className="form-group">
          <label>Descripción</label>
          <textarea className="form-control"
            name="descrip"
            value={formData.descrip}
            onChange={handleChange}
            placeholder="Descripción detallada de la propiedad"
          />
        </div>
        <div className="form-group">
          <label>Subir imágenes</label>
          <input type="file" className="form-control" multiple onChange={handleFileChange} />
        </div>
        <button type="submit">Agregar Propiedad</button>
      </form>
    </div>
  );
};

export default AddProperty;
