import React from 'react';
import SumarInm from './components/SumarInm.js';
import Menu from './components/Menu.js';

// Otros importaciones de componentes...

const SumarInmobiliaria = () => {
  return (
    <div className="vp-container">
    <div className="vp-main-content">
      <SumarInm />
    </div>
    <Menu />
  </div>
  );
};

export default SumarInmobiliaria;