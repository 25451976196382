import React from 'react';
import { Link } from 'react-router-dom';
import './SumarInm.css';
import despegar from '../assets/despegaprof.jpeg';
import red from '../assets/prof4.jpeg';
import sop from '../assets/prof3.jpeg';
import portal from '../assets/prof5.jpeg';
import prof from '../assets/prof1.jpeg';

const SumarInm = () => {
    return (
        <div className="sumar-inmobiliaria-container">
            <div className="somos-prof">
                <h1 id="fadeInText">Somos un grupo de jóvenes que buscamos dar nuevas soluciones al mercado inmobiliario.</h1>
                <div className="sumarMiInm">
                    <a href="https://wa.me/5492233390092?text=Hola!%20quiero%20hablar%20de%20negocios%20con%20respecto%20a%20Reservos.">
                        Sumar mi inmobiliaria
                    </a>
                </div>
            </div>

            <div className="crece-en-clientes container-profesional">
                <img src={despegar} alt="Aumentar clientes en Mar del Plata con Reservos" className="img-crece-prof" />
                <h2>Crecé con más clientes.</h2>
            </div>

            <div className="beneficios-prof container-profesional">
                <div className="img-red-beneficio">
                <img src={red} alt="Aumentar clientes en Mar del Plata con Reservos" className="img-a" />
                </div>
                <div className="texto-prof-beneficio">
                    <p>Accedé a un <b>grupo de productores</b> que captarán propiedades para tu inmobiliaria.</p>
                </div>
            </div>

            <div className="beneficios-prof container-profesional">
                <div className="texto-prof-apoyo">
                    <p>Vendé más con el apoyo de nuestras tecnologías y soporte técnico.</p>
                </div>
                <div className="img-sop-beneficio">
                <img src={sop} alt="Aumentar clientes en Mar del Plata con Reservos" className="img-b" />
                </div>
            </div>

            <div className="beneficios-prof container-profesional">
                <div className="img-portal-beneficio">
                <img src={portal} alt="Aumentar clientes en Mar del Plata con Reservos" className="img-a" />
                </div>
                <div className="texto-prof-beneficio">
                    <p>Los inmuebles se publican en nuestro portal, donde estamos creciendo a gran escala en usuarios. <b>No hay costo por publicar.</b></p>
                </div>
            </div>

            <div className="beneficios-prof container-profesional">
                <div className="texto-prof-apoyo">
                    <p>Vas poder incorporar nuestro innovador sistema de alquileres temporarios, donde el precio se mueve por la oferta y la demanda de forma automática.</p>
                </div>
                <div className="img-prof-apoyo">
                <img src={prof} alt="Aumentar clientes en Mar del Plata con Reservos" className="img-b" />
                </div>
            </div>

            <div className="container-profesional">
                <div className="title-valores">
                    <h3>¿Te identificás con nuestros valores?</h3>
                </div>
                <div className="valores-prof">
                    <div className="val1">
                        <h2>Respeto y Coordialidad</h2>
                        <p>Ser amable cada vez que puedas, respeta las opiniones de los demás y atrapa ideas que te ayuden a crecer.</p>
                    </div>
                    <div className="val2">
                        <h2>Honestidad y Trabajo</h2>
                        <p>Ser claro con los demás, con uno mismo y dar valor a cada cliente asesorandolo para que pueda suplir sus necesidades.</p>
                    </div>
                    <div className="val3">
                        <h2>Libertad y Misión Emprendedora</h2>
                        <p>No hay limites para crecer, dar todo el compromiso, dedicasión y buscar nuevas oportunidades.</p>
                    </div>
                </div> 
            </div>

            <div className="confian-en-nosotros"></div>
            <div className="consultas"></div>

            <div className="sumate-a-la-red">
                <p>Sumate a nuestra red inmobiliaria.</p>
                <div className="sumarMiInm">
                    <a href="https://wa.me/5492233390092?text=Hola!%20quiero%20hablar%20de%20negocios%20con%20respecto%20a%20reservos.">
                        Quiero sumarme
                    </a>
                </div>
            </div>
        </div>
    );
};

export default SumarInm;
