import React from 'react';
import ShareAndEarn from './components/ShareAndEarn.js';
import Menu from './components/Menu.js';

// Otros importaciones de componentes...

const CompartiGana = () => {
  return (
    <div className="vp-container">
    <div className="vp-main-content">
      <ShareAndEarn />
    </div>
    <Menu />
  </div>
  );
};

export default CompartiGana;
