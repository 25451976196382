import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, updateEmail, updatePassword, updateProfile, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import Loading from './Loading.js';
import './Account.css';

const Account = () => {
    const auth = getAuth();
    const db = getFirestore();

    const [loading, setLoading] = useState(true); // Estado de carga
    const [user, setUser] = useState(null); // Estado para el usuario autenticado
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [cbu, setCbu] = useState("");
    const [isNameEditable, setIsNameEditable] = useState(true);
    const [currentPassword, setCurrentPassword] = useState("");  // Para la reautenticación

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUser(user);
                setEmail(user.email);

                try {
                    const userDoc = await getDoc(doc(db, "users", user.uid));
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        setFullName(userData.fullName || "");
                        setPhoneNumber(userData.phoneNumber || "");
                        setCountry(userData.country || "");
                        setState(userData.state || "");
                        setCity(userData.city || "");
                        setAddress(userData.address || "");
                        setCbu(userData.cbu || "");
                        if (userData.fullName) {
                            setIsNameEditable(false);
                        }
                    }
                } catch (error) {
                    console.error("Error fetching user data: ", error);
                } finally {
                    setLoading(false); // Termina el estado de carga después de que los datos se hayan cargado
                }
            } else {
                setLoading(false); // Termina el estado de carga si no hay usuario
            }
        });

        return () => unsubscribe(); // Desuscribirse cuando el componente se desmonte
    }, [auth, db]);

    const reauthenticate = async () => {
        const credential = EmailAuthProvider.credential(user.email, currentPassword);
        try {
            await reauthenticateWithCredential(auth.currentUser, credential);
        } catch (error) {
            console.error("Error reauthenticating: ", error);
            throw new Error("La reautenticación falló. Por favor verifica tu contraseña actual.");
        }
    };

    const handleUpdateProfile = async (e) => {
        e.preventDefault();

        try {
            // Reautenticación si se va a cambiar el email o la contraseña
            if (email !== user.email || password) {
                await reauthenticate();  // Verifica con la contraseña actual
            }

            if (email !== user.email) {
                await updateEmail(auth.currentUser, email);
            }

            if (password) {
                await updatePassword(auth.currentUser, password);
            }

            const userRef = doc(db, "users", user.uid);
            await updateDoc(userRef, {
                phoneNumber,
                country,
                state,
                city,
                address,
                cbu,
            });

            if (isNameEditable) {
                await setDoc(userRef, { fullName }, { merge: true });
                setIsNameEditable(false);
            }

            alert("Perfil actualizado con éxito.");
        } catch (error) {
            console.error("Error updating profile: ", error);
            if (error.message === "La reautenticación falló. Por favor verifica tu contraseña actual.") {
                alert(error.message);
            } else {
                alert("Hubo un error al actualizar el perfil.");
            }
        }
    };

    // Mostrar un mensaje de carga mientras se esperan los datos del usuario
    if (loading) {
        return <div id="carga" className="containerLoading">
        <Loading />
      </div>;
    }

    // Si no hay usuario autenticado, mostramos un mensaje o redirigimos
    if (!user) {
        return <div>No has iniciado sesión. Por favor, inicia sesión para ver tu cuenta.</div>;
    }

    return (
        <div className="account-page">
            <h2>Mi Cuenta</h2>
            <form onSubmit={handleUpdateProfile} className="account-form">
                <div className="form-group">
                    <label>Correo electrónico</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Contraseña actual (para cambios de email/contraseña)</label>
                    <input
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Nueva Contraseña</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label>Nombre Completo</label>
                    <input
                        type="text"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        disabled={!isNameEditable}
                        required
                    />
                    {!isNameEditable && <small>No se puede editar una vez guardado.</small>}
                </div>

                <div className="form-group">
                    <label>Teléfono</label>
                    <input
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label>País</label>
                    <input
                        type="text"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label>Provincia/Estado</label>
                    <input
                        type="text"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label>Ciudad</label>
                    <input
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label>Dirección</label>
                    <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label>CBU o CVU</label>
                    <input
                        type="text"
                        value={cbu}
                        onChange={(e) => setCbu(e.target.value)}
                    />
                    <small>El nombre al cual este asociada tu cuenta bancaria, debe ser el mismo al nombre de tu cuenta de Reservos.</small>
                </div>

                <button type="submit" className="btn-update">Actualizar Perfil</button>
            </form>
        </div>
    );
};

export default Account;
