import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, addDoc, query, where } from 'firebase/firestore';
import { db } from './firebaseConfig.js';
import { useAuth } from './context/AuthContext.js'; // Asegúrate de tener AuthContext
import Loading from './components/Loading.js';
const Reservas = () => {
  const { propertyId } = useParams();
  const { currentUser } = useAuth();
  const [property, setProperty] = useState(null);
  const [reservations, setReservations] = useState([]);
  const [blockDate, setBlockDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const reservationsPerPage = 5; // Número de reservas por página
  const navigate = useNavigate();

  // Obtener la propiedad y verificar si el usuario es el propietario
  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const propertyRef = doc(db, 'properties', propertyId);
        const propertySnap = await getDoc(propertyRef);

        if (propertySnap.exists()) {
          const propertyData = propertySnap.data();

          // Verificar si el usuario autenticado es el propietario de la propiedad
          if (propertyData.idUsuario !== currentUser.uid) {
            alert('No tienes permiso para ver las reservas de esta propiedad.');
            navigate('/office'); // Redirige si no es el propietario
          } else {
            setProperty(propertyData); // Si es el propietario, mostrar las reservas
          }
        } else {
          console.log('La propiedad no existe.');
          navigate('/office'); // Redirige si la propiedad no existe
        }
      } catch (error) {
        console.error('Error al obtener la propiedad:', error);
        navigate('/office'); // Redirige en caso de error
      }
    };

    if (currentUser) {
      fetchProperty();
    }
  }, [currentUser, propertyId, navigate]);

  // Obtener reservas de la colección 'reservas' para esta propiedad
  useEffect(() => {
    const fetchReservations = async () => {
      if (property) {
        try {
          const reservationsRef = collection(db, 'reservas');
          const q = query(reservationsRef, where('propertyId', '==', propertyId));
          const querySnapshot = await getDocs(q);
          const reservationsData = querySnapshot.docs.map(doc => doc.data());
          setReservations(reservationsData);
        } catch (error) {
          console.error('Error al obtener las reservas:', error);
        }
      }
    };

    fetchReservations();
  }, [property, propertyId]);

  // Verificar si la fecha ya está reservada
  const checkIfDateIsAvailable = async (date) => {
    try {
      const reservationsRef = collection(db, 'reservas');
      const q = query(reservationsRef, where('propertyId', '==', propertyId), where('fecha', '==', date));
      const querySnapshot = await getDocs(q);
      return querySnapshot.empty; // Retorna true si no hay reservas para esa fecha
    } catch (error) {
      console.error('Error al verificar la disponibilidad de la fecha:', error);
      return false; // Retorna false si hubo un error
    }
  };

  // Manejar bloqueo de fechas
  const handleBlockDate = async () => {
    if (!blockDate) {
      alert('Por favor, selecciona una fecha.');
      return;
    }

    // Verificar si la fecha está disponible
    const isAvailable = await checkIfDateIsAvailable(blockDate);

    if (!isAvailable) {
      alert('Esta fecha ya está reservada o bloqueada.');
      return;
    }

    try {
      const blockData = {
        fecha: blockDate,
        propertyId: propertyId,
        bloqueadoPor: currentUser.uid, // Usuario que bloquea la fecha
      };

      // Agregar la fecha bloqueada a la colección de reservas
      await addDoc(collection(db, 'reservas'), blockData);

      // Actualizar el estado de reservas
      setReservations([...reservations, blockData]);
      setBlockDate(''); // Limpiar el campo de fecha
      alert('Fecha bloqueada correctamente.');
    } catch (error) {
      console.error('Error al bloquear la fecha:', error);
      alert('Hubo un error al bloquear la fecha.');
    }
  };

  // Obtener las reservas actuales para la página actual
  const indexOfLastReservation = currentPage * reservationsPerPage;
  const indexOfFirstReservation = indexOfLastReservation - reservationsPerPage;
  const currentReservations = reservations.slice(indexOfFirstReservation, indexOfLastReservation);

  // Cambiar la página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (!property) {
    return  <div id="carga" className="containerLoading">
    <Loading />
  </div>;
  }

  return (
    <div className="containerMisReservas">
      <h2>Reservas para {property.direccion}</h2>
      {reservations.length > 0 ? (
        <ul>
          {reservations.map((reservation, index) => (
            <li key={index}>
              <p>Fecha reservada: {reservation.fecha}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No hay reservas.</p>
      )}

      <div className="pagination">
      {Array.from({ length: Math.ceil(reservations.length / reservationsPerPage) }, (_, i) => (
        <button key={i + 1} onClick={() => paginate(i + 1)} className={currentPage === i + 1 ? 'active' : 'btn moli-o'}>
          {i + 1}
        </button>
      ))}
    </div>

      <div>
        <h3>Bloquear una fecha</h3>
        <div className="input-group-alq">
        <input className="inputBusAlq" type="date" value={blockDate} onChange={(e) => setBlockDate(e.target.value)} 
        />
        <button className="btn moli-o" onClick={handleBlockDate}>Bloquear Fecha</button>
        </div>
                
      </div>
    </div>
  );
};

export default Reservas;
