import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebaseConfig.js';
import { doc, getDoc } from 'firebase/firestore';
import Loading from '../components/Loading.js';
const AuthContext = createContext();


export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setCurrentUser(user);
            if (user) {
                try {  
                const userDoc = await getDoc(doc(db, 'users', user.uid));
                if (userDoc.exists()) {
                    setUserRole(userDoc.data().role);
                } else {
                    setUserRole(null); // Si el documento del usuario no existe
                }
                } catch (error) {
                    console.error('Error al obtener el documento del usuario:', error);
                    setUserRole(null);
                }
            }else {
                setUserRole(null);
            }
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const logout = () => {
        return auth.signOut();
      };
    
      const value = {
        currentUser,
        userRole,
        logout, 
      };

      if (loading) {
        return <div id="carga" className="containerLoading">
        <Loading />
      </div>; 
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
