import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext.js';
import Loading from './Loading.js'; // Importamos el componente de carga
import './ShareAndEarn.css';
import shareAndWinImage from '../assets/reservos-beach.jpeg';

const ShareAndEarn = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser !== undefined) {
      setLoading(false);
    }
  }, [currentUser]);

  if (loading) {
    return <div id="carga" className="containerLoading">
    <Loading />
  </div>; // Mostrar el componente de carga
  }

  // Generar los enlaces de compartir utilizando el código de referido del usuario
  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=https://reservos.com/?referrer=${currentUser?.uid || ''}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=https://reservos.com/?referrer=${currentUser?.uid || ''}`,
    x: `https://x.com/intent/tweet?text=Encontrá%20las%20mejores%20oportunidades%20inmobiliarias%20en%20Reservos!%20https://reservos.com/?referrer=${currentUser?.uid || ''}`,
    whatsapp: `https://api.whatsapp.com/send?text=Encontrá%20las%20mejores%20oportunidades%20inmobiliarias%20en%20Reservos!%20https://reservos.com/?referrer=${currentUser?.uid || ''}`,
  };

  const shareSite = (platform) => {
    window.open(shareLinks[platform], '_blank');
  };

  return (
    <section className="partes parte-share-props" id="compartir-y-ganar">
      <div className="cont-into">
        <h2 data-text="Crecé...">Crecé...</h2>
      </div>
      <div className="refiere-el-sitio">
        <h2>¡Comparte nuestro sitio y gana recompensas!</h2>
        <div className="contenido-refs">
          <div className="texto-r">
            <p>
              Si eres un usuario satisfecho con nuestros servicios, compartí nuestro sitio en tus redes sociales 
              y obtené recompensas económicas cuando alguien se registre en nuestro sitio a través de tu enlace 
              personalizado y realice su primer reserva en venta o alquiler.
            </p>
          </div>
          <div className="iconos-refs">
            <ul className="wrapper-icons sci">
              <li className="icon comentarios">
                <a href={shareLinks.facebook} target="_blank" rel="noopener noreferrer">
                  <span><i className="fa-brands fa-facebook"></i></span>
                </a>
              </li>
              <li className="icon comentarios">
                <a href={shareLinks.linkedin} target="_blank" rel="noopener noreferrer">
                  <span><i className="fa-brands fa-linkedin"></i></span>
                </a>
              </li>
              <li className="icon comentarios">
                <a href={shareLinks.x} target="_blank" rel="noopener noreferrer">
                  <span><i className="fa-brands fa-x-twitter"></i></span>
                </a>
              </li>
              <li className="icon comentarios">
                <a href={shareLinks.whatsapp} target="_blank" rel="noopener noreferrer">
                  <span><i className="fa-brands fa-whatsapp"></i></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <img src={shareAndWinImage} alt="PAGINA QUE DA RECOMPENSAS POR COMPARTIR SU CONTENIDO" />
        <p>
          Nuestro programa de referidos es una forma efectiva de 
          recompensarte por tu recomendación y, al mismo tiempo, atraer a nuevos clientes a nuestro sitio. 
          Recuerda que para recibir la recompensa, es necesario que el nuevo cliente se registre a través de tu 
          enlace personalizado y realice su primera compra. ¡Ayúdanos a crecer y obtén beneficios por hacerlo!
        </p>    
        <p>
          Si un usuario al cual referiste, realiza una consulta y posteriormente una operación inmobiliaria de venta a través del Reservos, ganarás premios en dólares.
        </p> 
        <p>
          También ganás premios si un amigo tuyo pone en alquiler temporario o si reserva una propiedad en alquiler.
        </p>       
      </div>
      <ul className="lista-premios-ref">
        <li onClick={() => shareSite('facebook')}>
          <h3>Gana dinero refiriendo a un amigo que quiere vender una propiedad</h3>
          <p>¿Tienes un amigo que desea vender su casa? ¡No pierdas la oportunidad de ganar dinero! Refierelo a nuestro portal y obtén una comisión por cada venta exitosa.</p>
        </li>
        <li onClick={() => shareSite('linkedin')}>
          <h3>Gana dinero refiriendo a un amigo que quiere rentar una propiedad</h3>
          <p>¿Conoces a alguien que quiera poner su propiedad en alquiler? Refierelo a nuestra plataforma y gana una comisión por cada contrato de alquiler firmado.</p>
        </li>
        <li onClick={() => shareSite('x')}>
          <h3>Gana dinero refiriendo a un amigo que desea comprar una propiedad</h3>
          <p>Si tienes un amigo interesado en comprar una propiedad, refierelo a nuestro portal inmobiliario. No solo le ayudarás a encontrar su hogar ideal, sino que también ganarás dinero por cada compra realizada.</p>
        </li>
        <li onClick={() => shareSite('whatsapp')}>
          <h3>Gana dinero refiriendo a un amigo que desea comprar una propiedad</h3>
          <p>Si tienes un amigo interesado en comprar una propiedad, refierelo a nuestro portal inmobiliario. No solo le ayudarás a encontrar su hogar ideal, sino que también ganarás dinero por cada compra realizada.</p>
        </li>
      </ul>
    </section>
  );
};

export default ShareAndEarn;