import React from 'react';
import { Link } from 'react-router-dom';
import './Success.css'; // Importa el CSS para estilos personalizados

const Success = () => {
  return (
    <div className="success-container">
      <h1>¡Pago Exitoso!</h1>
      <p>Gracias por tu compra. Tu pago ha sido procesado con éxito.</p>
      <Link to="/" className="button">Volver al Inicio</Link>
    </div>
  );
};

export default Success;
