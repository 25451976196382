import React from 'react';
import Faq from './components/Faq.js';
import Menu from './components/Menu.js';

// Otros importaciones de componentes...

const FaqPage = () => {
  return (
    <div className="vp-container">
    <div className="vp-main-content">
      <Faq />
    </div>
    <Menu />
  </div>
  );
};

export default FaqPage;