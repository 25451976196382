import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import './PropertyFilter.css';
import PropertyCard from './PropertyCard.js';
import nlp from 'compromise';

const PropertyFilter = () => {
  const [query, setQuery] = useState('');
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [barrios, setBarrios] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInitiated, setSearchInitiated] = useState(false);

  useEffect(() => {
    const fetchProperties = async () => {
      const propertiesSnapshot = await getDocs(collection(db, 'properties'));
      const propertiesList = propertiesSnapshot.docs.map((doc) => doc.data());

      // Generar listas únicas de barrios y ciudades
      const uniqueBarrios = [...new Set(propertiesList.map((prop) => prop.barrio?.toLowerCase()))];
      const uniqueCiudades = [...new Set(propertiesList.map((prop) => prop.ciudad?.toLowerCase()))];

      setBarrios(uniqueBarrios);
      setCiudades(uniqueCiudades);
    };

    fetchProperties();
  }, []);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const processQuery = async () => {
    setLoading(true);
    setSearchInitiated(true);

    const propertiesSnapshot = await getDocs(collection(db, 'properties'));
    const propertiesList = propertiesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const results = propertiesList.filter((property) => {
      return filterByQuery(property, query);
    });

    setFilteredProperties(results);
    setLoading(false);
  };

  const filterByQuery = (property, query) => {
    const doc = nlp(query.toLowerCase());
    let matchFound = false;
  
    // Filtrar solo propiedades de venta
    if (property.operacion?.toLowerCase() !== 'venta') {
      return false;
    }
  
    // Filtrar propiedades que tienen el campo "precio" definido (consideradas como vendidas)
    if (property.hasOwnProperty('precio')) {
      return false;
    }
  
    // Filtro por tipo de propiedad (departamento, casa, etc.)
    if (doc.has('departamento') || doc.has('casa') || doc.has('ph') || doc.has('local') || doc.has('terreno')) {
      const propertyType = doc.has('departamento') ? 'departamento' :
                          doc.has('casa') ? 'casa' :
                          doc.has('ph') ? 'ph' :
                          doc.has('local') ? 'local' :
                          doc.has('terreno') ? 'terreno' : '';
  
      if (property.tipo?.toLowerCase() === propertyType) {
        matchFound = true;
      } else {
        return false;
      }
    }
  
  // Filtro por número de ambientes
  const numberWordsToNumbers = {
  'un': 1,
  'uno': 1,
  'una': 1,
  'dos': 2,
  'tres': 3,
  'cuatro': 4,
  'cinco': 5,
  'seis': 6,
  'siete': 7,
  'ocho': 8,
};

// Filtro por número de ambientes
const ambientesMatch = doc.match('#Value+ ambientes?');

if (ambientesMatch.found) {
  const ambientesList = ambientesMatch.out('array').map(text => {
    const numberWord = text.split(' ')[0].toLowerCase();
    const number = parseInt(numberWord, 10);
    
    // Si es un número válido, úsalo; de lo contrario, intenta convertir la palabra a número
    if (!isNaN(number)) {
      return number;
    } else if (numberWordsToNumbers[numberWord] !== undefined) {
      return numberWordsToNumbers[numberWord];
    } else {
      return null;  // Caso donde no es un número ni palabra reconocida
    }
  }).filter(num => num !== null);

  if (ambientesList.includes(property.amb)) {
    matchFound = true;
  } else {
    return false;
  }
}


  // Filtro por barrio o zona dinámica
  const barrioMatch = doc.match('barrio #Noun+');
  if (barrioMatch.found) {
    const barrio = barrioMatch.text().replace('barrio ', '').trim();
    if (property.barrio?.toLowerCase() === barrio.toLowerCase()) {
      matchFound = true;
    } else {
      return false;
    }
  }

  const zonaMatch = doc.match('zona #Noun+');
  if (zonaMatch.found) {
    const zona = zonaMatch.text().replace('zona ', '').trim();
    if (property.barrio?.toLowerCase() === zona.toLowerCase()) {
      matchFound = true;
    } else {
      return false;
    }
  }
  
    // Filtro por ciudad dinámica
    const ciudadMatch = ciudades.find(ciudad => doc.has(ciudad));
    if (ciudadMatch) {
      if (property.ciudad?.toLowerCase() === ciudadMatch) {
        matchFound = true;
      } else {
        return false;
      }
    }
  
    // Filtro por rango de precios
    const priceRangeMatch = doc.match('#Value+ a #Value+ dolares');
    if (priceRangeMatch.found) {
      const [minPrice, maxPrice] = priceRangeMatch.out('array')[0].split(' a ').map(Number);
      if (property.publicacion >= minPrice && property.publicacion <= maxPrice) {
        matchFound = true;
      } else {
        return false;
      }
    }
  
    // Filtro por antigüedad de publicación
    const daysAgoMatch = doc.match('#Value+ días');
    if (daysAgoMatch.found) {
      const daysAgo = parseInt(daysAgoMatch.text().split(' ')[0], 10);
      const today = new Date();
      const propertyDate = new Date(property.createdAt?.toDate());
      const diffDays = Math.ceil((today - propertyDate) / (1000 * 60 * 60 * 24));
      if (diffDays <= daysAgo) {
        matchFound = true;
      } else {
        return false;
      }
    }
  
    // Filtro por características booleanas
    const booleanFilters = [
      { key: 'financia', queryTerm: 'financia' },
      { key: 'baulera', queryTerm: 'baulera' },
      { key: 'cochera', queryTerm: 'cochera' },
      { key: 'credito', queryTerm: 'credito' },
      { key: 'dep', queryTerm: 'dependencia' },
      { key: 'permuta', queryTerm: 'permuta' },
    ];
  
    for (let filter of booleanFilters) {
      if (doc.has(filter.queryTerm)) {
        if (property[filter.key]) {
          matchFound = true;
        } else {
          return false;
        }
      }
    }
  
    // Filtro por vista (como string)
    const vistaMatch = doc.match('vista #Noun+');
    if (vistaMatch.found) {
      const vista = vistaMatch.text().replace('vista ', '').trim();
      if (property.vista?.toLowerCase() === vista) {
        matchFound = true;
      } else {
        return false;
      }
    }
  
    // Si no se encontró ningún match claro, no mostrar la propiedad
    return matchFound || !query.trim();
  };
  

  return (
    <div className="property-filter">
      <textarea
        placeholder="Escribe lo que buscas... Ejemplo: 'departamento 3 ambientes en Mar del Plata entre 5000000 a 10000000 dolares en los últimos 30 días con vista al mar'"
        value={query}
        onChange={handleInputChange}
        className="form-control filter-textarea"
      ></textarea>
      <button onClick={processQuery} className="filter-button">
        {loading ? 'Buscando...' : 'Buscar'}
      </button>
      <div>
        {searchInitiated && (
          loading ? (
            <p className="loading-message">Buscando propiedades...</p>
          ) : filteredProperties.length > 0 ? (
            <ul>
              {filteredProperties.map((property) => (
                <PropertyCard key={property.id} property={property} archivos={property.archivos || []} />
              ))}
            </ul>
          ) : (
            <p>No se encontraron propiedades que coincidan con tu búsqueda.</p>
          )
        )}
      </div>
    </div>
  );
};

export default PropertyFilter;
