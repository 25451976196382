import React from 'react';
import { Link } from 'react-router-dom';
import './Failure.css'; // Importa el CSS para estilos personalizados

const Failure = () => {
  return (
    <div className="failure-container">
      <h1>¡Algo Salió Mal!</h1>
      <p>Tu pago no pudo ser procesado. Por favor, intenta nuevamente.</p>
      <Link to="/" className="button">Volver al Inicio</Link>
    </div>
  );
};

export default Failure;
