// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyCcu7-HNpnQLkrNKbjd5CgtoOaJaJVhoIY",
  authDomain: "reservos-20bac.firebaseapp.com",
  projectId: "reservos-20bac",
  storageBucket: "reservos-20bac.appspot.com",
  messagingSenderId: "102886823253",
  appId: "1:102886823253:web:1534a46220a0a6bebacbdc"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Obtiene instancias de Firestore, Auth y Storage
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const messaging = getMessaging(app);

export { db, auth, storage, messaging };
