import React, { useEffect, useState } from 'react';
import "./HomePage.css";
import { Link } from 'react-router-dom';
import { auth } from './firebaseConfig.js';
import { onAuthStateChanged } from 'firebase/auth'; 
import Menu from './components/Menu.js';
import { useLocation } from 'react-router-dom'; // Para obtener parámetros de la URL

const HomePage = () => {
  const [user, setUser] = useState(null);
  const location = useLocation(); // Hook para acceder a la URL

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  // Capturamos el parámetro referrer de la URL y lo almacenamos en localStorage
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referrerUid = params.get('referrer');
    
    if (referrerUid) {
      localStorage.setItem('referrer', referrerUid);
    }
  }, [location]);

  return (
    <>
      <div className="vp-container">
        <div className="vp-main-content">
          <section className="secRealEstate">
            <div className="seccionFiltros">
              <div className="titulosReal">
                <h2>Bienvenido a Reservos</h2>
                <h2>Reservá tu propiedad de alquiler temporario o disponible en venta.</h2>
              </div>
              <div className="avisoLegal">
                <p>
                  Todas las propiedades que figuran en este portal son publicadas por propietarios bajo su
                  responsabilidad o inmobiliarias en poder de Corredores Inmobiliarios Matriculados. En cada post
                  figuran los datos del martillero correspondiente, el cual será responsable de la operación inmobiliaria.
                </p>
              </div>
              {!user && (
                <div className="conLog">
                  <p>Inicia sesión o registrate fácilmente si no tenés cuenta.</p>
                  <div className="loginRealEstateContainer">
                    <ul>
                      <Link to="/Login"> <li className="login">Inicio</li> </Link>
                      <Link to="/Register"><li className="registro">Registro</li> </Link>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
        <Menu />
      </div>
    </>
  );
};

export default HomePage;
