import React from 'react';
import { auth } from './firebaseConfig.js';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';


const provider = new GoogleAuthProvider();

const RegisterWithGoogle = () => {
  const handleRegister = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Guarda el usuario en Firestore con un rol predeterminado
      const firestore = getFirestore();
      await setDoc(doc(firestore, "users", user.uid), {
        email: user.email,
        role: 'user' // Cambia a 'admin' si es necesario
      });

      alert('Registro exitoso con Google');
    } catch (error) {
      console.error("Error registering with Google: ", error);
      alert("Error al registrarse con Google");
    }
  };

  return (
    <button onClick={handleRegister} className="google-button">
      Registrarse con Google
    </button>
  );
};

export default RegisterWithGoogle;
